.homeVideo{
    background-size: cover;
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.rowm {
    display: flex;
    justify-content: space-evenly;
}
.ServiceButton{
    width: 100%;
    height:30px;
    color: aliceblue;
    text-align: center;
    text-decoration: none;
}
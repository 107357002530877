.Navibar{
    background-color: #F11248;
    height:80px;
    width: 100%;
    
}
.logo {
    width:120px;
    height: 80px;
    float: left;
}
.navMenu{
    text-decoration: none; 
    float: left;
    padding: 30px 20px 20px 30px;
    color: aliceblue;
    
}
.navMenu:hover{
    border-color: #ed8888;
    color: bisque;
}
.NavSearch{
    float: left;
    padding: 30px 20px 20px 30px;
}
.searchButton{
    height:30px;
    width:80px;
}
.searchBar {
    height:30px;
    width:180px;
}
